import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SidebarModule } from '../shared/components/sidebar/sidebar.module';
import { NavbarModule } from '../shared/components/navbar/navbar.module';
import { FooterModule } from '../shared/components/footer/footer.module';

import { AuthGuard } from '../shared/guards/auth.guard';
import { AuthChildGuard } from '../shared/guards/authChild.guard';

import { PagesRoutingModule } from './pages.routing.module';

import { RestClientService } from './../shared/services/restClient/restClient.service';
import { GenericService } from '../shared/services/genericService/genericService.service';
import { AuthService } from '../shared/services/auth/auth.service';

import { PagesComponent } from './pages.component';

@NgModule({
  imports: [
    CommonModule,
    PagesRoutingModule,
    SidebarModule,
    NavbarModule,
    FooterModule
  ],
  declarations: [
    PagesComponent
  ], 
  providers: [
    GenericService,
    RestClientService,
    AuthService,
    AuthGuard
  ],
  exports: [
  ]
})

export class PagesModule {
}
