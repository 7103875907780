import { Component, OnInit } from '@angular/core';

import { CookieService } from 'ng2-cookies';

import { AuthService } from './../../../services/auth/auth.service';
import { GenericService } from './../../../services/genericService/genericService.service';

import { Usuario } from './../../../models/user.model';

@Component({
    moduleId: module.id,
    selector: 'sidebar-user-cmp',
    templateUrl: 'user.component.html',
})

export class SidebarUserComponent implements OnInit {

    private _usuario: Usuario;
    constructor(
        private _authSvc: AuthService,
        private _cookieService: CookieService,
    ) {
        this._usuario = {
            nome: this._cookieService.get('Nome'),
            email: this._cookieService.get('UserMail'),
        };
    }

    ngOnInit(): void {
    }

    public sair(){
        this._authSvc.sair();
    }

    get usuario(){
        return this._usuario;
    }
}