import { Injectable, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CookieService } from 'ng2-cookies';

import { RestClientService } from '../restClient/restClient.service';
import { GenericService } from './../genericService/genericService.service';

import { Login } from '../../models/auth.model';
import { Usuario } from '../../models/user.model';
import { ApiConfigModel } from '../../models/api-config.model';

declare var notify: any;

@Injectable()
export class AuthService {

  private _user: Usuario;
  private returnUrl: string;
  private _config: ApiConfigModel = {
    Debug: false,
    Prefixo: '/Usuario',
    UrlDebug: 'http://localhost:53236'
  };

  private static _autenticado: boolean = false;
  private static _expires: string;

  constructor(
    private _cookieService: CookieService,
    private _router: Router,
    private _routerActive: ActivatedRoute,
    private _rest: RestClientService,
    private _genericSvc: GenericService
  ) {
    AuthService._expires = this._cookieService.get('Expires');
    if (new Date() >= new Date(AuthService._expires)) {
      this.usuarioNaoAutenticado();
    }

    if (this._cookieService.check('AccessToken')) {
      AuthService._autenticado = true;
    }

    this._user = {
      nome: this._cookieService.get('UserName'),
      email: this._cookieService.get('UserMail')
    }
    this.returnUrl = this._routerActive.queryParams['_value'].returnUrl;
  }

  public entrar(login: Login) {
    notify.show('top', 'right', 'rose', 'Aguarde enquanto realizamos seu login...');
    this.setToken(login);
  }

  public sair() {
    this.usuarioNaoAutenticado();
    this._router.navigate(['/auth/login']);
  }

  private armazenarToken(dados: any) {
    this._cookieService.set('Nome', dados.Nome);
    this._cookieService.set('AccessToken', dados.access_token);
    this._cookieService.set('TokenType', dados.token_type);
    this._cookieService.set('ExpiresIn', dados.expires_in);
    this._cookieService.set('UserId', dados.user_id);
    this._cookieService.set('UserName', dados.user_name);
    this._cookieService.set('UserMail', dados.user_mail);
    this._cookieService.set('Issued', dados['.issued']);
    this._cookieService.set('Expires', dados['.expires']);

    this._user = {
      nome: this._cookieService.get('UserName'),
      email: this._cookieService.get('UserMail')
    }
  }

  private setToken(login: Login) {
    const segmento = '/Token';

    const params = {
      Username: login.usuario,
      Password: login.senha
    }

    this._rest.get(this._config, segmento, params).subscribe(data => {

      this.armazenarToken(data);
      AuthService._autenticado = true;

      this._router.navigate([this.returnUrl ? this.returnUrl : '/pages/dashboard']);
      notify.close();

    }, error => {
      notify.show('top', 'right', 'danger', 'Erro de usuário / senha.');
      AuthService._autenticado = false;
    });
  }

  private usuarioNaoAutenticado() {
    this._cookieService.deleteAll();
    AuthService._autenticado = false;
  }

  usuarioAutenticado() {
    if (new Date() >= new Date(AuthService._expires)) {
      this.usuarioNaoAutenticado();
    }
    return AuthService._autenticado;
  }

  get usuario() {
    return this._user;
  }
}