import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

registerLocaleData(localePt, 'pt');

import { CookieService } from 'ng2-cookies';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app.routing.module';

import { AuthModule } from './auth/auth.module';
import { PagesModule } from './pages/pages.module';
import { MatTableModule } from '@angular/material';

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpModule,
        AuthModule,
        PagesModule,
        BrowserAnimationsModule
    ],
    declarations: [
        AppComponent
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        { provide: LOCALE_ID, useValue: "pt" },
        CookieService
    ],
    exports: [
        MatTableModule,
    ]
})
export class AppModule { }
