import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../shared/services/auth/auth.service';

declare var notify:any;
declare var $:any;

@Component({
    moduleId:module.id,
    selector: 'login-cmp',
    templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit{

    public _formulario: FormGroup;
    private _erroMensagem: string = '';
    
    constructor(
        private _formBuilder: FormBuilder,
        private _authService: AuthService){
            this._formulario = this._formBuilder.group({
                usuario: ['', Validators.required],
                senha: ['', Validators.required]
              });
    }

    ngOnInit(){
        this.checkFullPageBackgroundImage();

        setTimeout(function(){
            // after 1000 ms we add the class animated to the login/register card
            $('.card').removeClass('card-hidden');
        }, 700)
    }

    checkFullPageBackgroundImage(){
        var $page = $('.full-page');
        var image_src = $page.data('image');

        if(image_src !== undefined){
            var image_container = '<div class="full-page-background" style="background-image: url(' + image_src + ') "/>'
            $page.append(image_container);
        }
    };

    entrar() {
        if(this._formulario.invalid)
        {
            notify.show('top', 'right', 'danger', 'Usuário e senha devem ser preenchidos.');
            return;
        }

        this._authService.entrar(this._formulario.value);
        if (this._authService.usuarioAutenticado() === false)
        {
          this._erroMensagem = 'Usuário e senha não correspondem.';
        }
      }
}
