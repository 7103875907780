import { EventEmitter, Injectable } from '@angular/core';

import { MenuItens } from './../../components/sidebar/sidebar.metadata';

import { ParametrosConfig } from '../../models/parametros-config.model';

import { Usuario } from '../../models/user.model';

@Injectable()
export class GenericService {

    constructor() { } 

    public emitters: {
        [nomeEvento: string]: EventEmitter<any>
    } = {};

    listaDeEventos(nomeEvento: string): EventEmitter<any> {
        if (!this.emitters[nomeEvento])
            this.emitters[nomeEvento] = new EventEmitter<any>();
        return this.emitters[nomeEvento];
    };

    gerarRota(rotas: any[], limit?: number): string {
        if (limit) rotas = rotas.slice(0, limit);

        let rota = rotas.reduce(function (anterior, proximo) {
            return anterior + '/' + proximo;
        });

        return rota;
    };

    validarParametrosPadroes(parametros: any) {
        let div = Math.floor(parametros.deslocamento / parametros.limite);

        parametros.deslocamento = div * parametros.limite;
        parametros.limite = parametros.limite;
        parametros.ordenarPor = parametros.ordenarPor;
        parametros.pagina = div + 1;

        return parametros;
    }

    validarFiltros(filtros: any, properties?: string[]): boolean {
        if (properties) {
            properties.forEach(prop => {
                if (!filtros[prop]) {
                    delete filtros[prop];
                }
            })
            let values = Object.values(filtros);
            return filtros.every(s => s);
        }

        let values = Object.values(filtros);
        return values.some(s => s);
    }

    gerarRotaPorString(rota: string): string {
        let segmentos = rota.split('/');
        let path: string = '/';
        for (let i = 1; i < segmentos.length; i++) {
            path += segmentos[i] + '/';
        }
        return path.substr(0, path.length - 1);
    };

    removerParametros(parametros: any): any {
        for (var prop in parametros) {

            if (!parametros[prop] && parametros[prop] !== 0 && parametros[prop] !== false) {
                delete parametros[prop];
            } else {
                if (typeof parametros[prop] === 'string')
                    parametros[prop] = parametros[prop].trim();
            }
        }
        return parametros;
    }

    getTitulo(items: MenuItens[], rota: string): string {
        let titulo: string = '';
        items.forEach(item => {
            if (item.unique && item.path == rota) {
                titulo = item.title;
                return;
            }
            else if (!item.unique) {
                item.childrens.forEach(filho => {
                    if (filho.path == rota) {
                        titulo = filho.title;
                        return;
                    };
                })
            }
        });

        return titulo;
    };
}
