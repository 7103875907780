import { MenuItens } from './sidebar.metadata';


export const menuItens: MenuItens[] =
[
    {
        id: "dashboard",
        path: "/pages/dashboard",
        unique: true,
        title: "Dashboard",
        icon: "apps",
        icontype: "material-icons",
        childrens: []
    },
    {
        id: "clientes",
        path: "/pages/clientes",
        unique: false,
        title: "Clientes",
        icon: "face",
        icontype: "material-icons",
        childrens: [
            {
                initials: "LT",
                title: "Listar",
                path: "/pages/clientes"
            }
        ]
    },
    {
        id: "orcamentos",
        path: "/pages/orcamentos",
        unique: false,
        title: "Orçamentos",
        icon: "attach_money",
        icontype: "material-icons",
        childrens: [
            {
                initials: "LT",
                title: "Listar",
                path: "/pages/orcamentos/listar"
            },
            {
                initials: "NV",
                title: "Novo",
                path: "/pages/orcamentos/novo"
            }
        ]
    },
    {
        id: "pedidos",
        path: "/pages/pedidos",
        unique: true,
        title: "Pedidos",
        icon: "assignment",
        icontype: "material-icons",
        childrens: []
    },
    {
        id: "depoimentos",
        path: "/pages/depoimentos",
        unique: true, 
        title: "Depoimentos",
        icon: "chat",
        icontype: "material-icons",
        childrens: []
    },
    {
        id: "veiculos",
        path: "/pages/veiculos",
        unique: false,
        title: "Veículos",
        icon: "directions_car",
        icontype: "material-icons",
        childrens: [
            {
                initials: "LT",
                title: "Listar",
                path: "/pages/veiculos/listar"
            }
        ]
    },
    {
        id: "agentes",
        path: "/pages/agentes",
        unique: false, 
        title: "Agentes",
        icon: "assessment",
        icontype: "material-icons",
        childrens: [
            {
                initials: "PE",
                title: "Pedidos com Agente",
                path: "/pages/agentes/listar"
            }
        ]
    },
    {
        id: "relatorios",
        path: "/pages/relatorios",
        unique: false,
        title: "Relatórios",
        icon: "assessment",
        icontype: "material-icons",
        childrens: [
            {
                initials: "PP",
                title: "Pedidos Pagos",
                path: "/pages/relatorios/pedidospagos"
            }, 
            {
                initials: "FI",
                title: "Financeiro",
                path: "/pages/relatorios/financeiro"
            }
        ]
    },
    {
        id: "configuracoes",
        path: "/pages/configuracoes",
        unique: false,
        title: "Configurações",
        icon: "settings",
        icontype: "material-icons",
        childrens: [
            {
                initials: "TA",
                title: "Tarifas",
                path: "/pages/configuracoes/tarifas"
            },
            {
                initials: "SE",
                title: "Serviços",
                path: "/pages/configuracoes/servicos"
            },
            {
                initials: "AG",
                title: "Níveis de Agentes",
                path: "/pages/configuracoes/agentes"
            },
            {
                initials: "CA",
                title: "Campanhas",
                path: "/pages/configuracoes/campanhas"
            },
            {
                initials: "DE",
                title: "Config. Detran",
                path: "/pages/configuracoes/detran"
            }
        ]
    },
    {
        id: "emails",
        path: "/pages/emails",
        unique: true,
        title: "Emails",
        icon: "email",
        icontype: "material-icons",
        childrens: []
    },
];