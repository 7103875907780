import { ApiConfigModel } from './../../models/api-config.model';
import { Component, OnInit, Renderer, ViewChild, ElementRef, Directive, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

import { Subscription, Observable } from 'rxjs/Rx';

import { GenericService } from './../../services/genericService/genericService.service';
import { RestClientService } from './../../services/restClient/restClient.service';

import { menuItens } from '../sidebar/sidebar-routes.config';
import { MenuItens } from './../sidebar/sidebar.metadata';

var misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
}
declare var $: any;
@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit, OnDestroy {
    private _listTitles: any[];
    location: Location;
    private _nativeElement: Node;
    private _toggleButton;
    private _sidebarVisible: boolean;
    private _menuItens: MenuItens[];
    private _title: string = '';
    private _inscricaoCreditos: Subscription
    private _inscricaoRota: Subscription

    public creditos: number;
    @ViewChild("navbar-cmp") button;

    constructor(
        location: Location,
        private _genericSvc: GenericService,
        private _restClient: RestClientService,
        private _router: Router,
        private _renderer: Renderer,
        private _element: ElementRef) {
        this.location = location;
        this._nativeElement = _element.nativeElement;
        this._sidebarVisible = false;
    }

    ngOnInit() {
        this._menuItens = menuItens;
        this.getTitle();

        this._inscricaoRota = this._router.events.subscribe((dados) => {
            if (dados instanceof NavigationEnd) {
                this.getTitle(dados.url);
            }
        });
        Observable.timer(0, 600000).subscribe(() =>
            this.consultarCreditos()
        );

        var navbar: HTMLElement = this._element.nativeElement;
        this._toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        if ($('body').hasClass('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        $('#minimizeSidebar').click(function () {
            var $btn = $(this);

            if (misc.sidebar_mini_active == true) {
                $('body').removeClass('sidebar-mini');
                misc.sidebar_mini_active = false;

            } else {
                setTimeout(function () {
                    $('body').addClass('sidebar-mini');

                    misc.sidebar_mini_active = true;
                }, 300);
            }

            // we simulate the window Resize so the charts will get updated in realtime.
            var simulateWindowResize = setInterval(function () {
                window.dispatchEvent(new Event('resize'));
            }, 180);

            // we stop the simulation of Window Resize after the animations are completed
            setTimeout(function () {
                clearInterval(simulateWindowResize);
            }, 1000);
        });
    }
    isMobileMenu() {
        if ($(window).width() < 991) {
            return false;
        }
        return true;
    }
    sidebarToggle() {
        var toggleButton = this._toggleButton;
        var body = document.getElementsByTagName('body')[0];

        if (this._sidebarVisible == false) {
            setTimeout(function () {
                toggleButton.classList.add('toggled');
            }, 500);
            body.classList.add('nav-open');
            this._sidebarVisible = true;
        } else {
            this._toggleButton.classList.remove('toggled');
            this._sidebarVisible = false;
            body.classList.remove('nav-open');
        }
    }

    getTitle(rota: string = '') {
        if (!rota) {
            let rotaCompleta = this.location.prepareExternalUrl(this.location.path());
            rota = this._genericSvc.gerarRotaPorString(rotaCompleta);
        }
        this._title = this._genericSvc.getTitulo(this._menuItens, rota);
    }

    private consultarCreditos() {
        let apiConfig: ApiConfigModel = {
            Debug: false,
            Prefixo: '/DirectDigital',
            UrlDebug: ''
        };
        this._restClient.get(apiConfig, '/ConsultarSaldoCreditos').subscribe(
            dados => this.creditos = dados ? dados.TotalCreditosRestantes : 0
        )
    }

    get title() {
        return this._title;
    }

    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }

    ngOnDestroy() {
        this._inscricaoRota.unsubscribe();
    }
}
