import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthComponent } from './auth/auth.component';

const AppRoutes: Routes = [
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: 'auth',  loadChildren: './auth/auth.module#AuthModule' },
  { path: 'pages', loadChildren: './pages/pages.module#PagesModule' },
  { path: '**', redirectTo: 'pages' }
];

@NgModule({
    imports: [RouterModule.forRoot(AppRoutes, { enableTracing: false ,useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule {}