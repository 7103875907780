import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './../shared/guards/auth.guard';

import { PagesComponent } from './pages.component';

const PagesRoutes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'configuracoes',
        loadChildren: './configuracoes/configuracoes.module#ConfiguracoesModule',
      },
      {
        path: 'clientes',
        loadChildren: './clientes/clientes.module#ClientesModule',
      },
      {
        path: 'orcamentos',
        loadChildren: './orcamentos/orcamentos.module#OrcamentosModule',
      },
      {
        path: 'pedidos',
        loadChildren: './pedidos/pedidos.module#PedidosModule',
      },
      {
        path: 'depoimentos',
        loadChildren: './depoimentos/depoimentos.module#DepoimentosModule',
      },
      {
        path: 'usuarios',
        loadChildren: './usuarios/usuarios.module#UsuariosModule',
      },
      {
        path: 'veiculos',
        loadChildren: './veiculos/veiculos.module#VeiculosModule',
      },
      {
        path: 'agentes',
        loadChildren: './agentes/agentes.module#AgentesModule',
      },
      {
        path: 'relatorios',
        loadChildren: './relatorios/relatorios.module#RelatoriosModule',
      },
      {
        path: 'emails',
        loadChildren: './emails/emails.module#EmailsModule',
      }
    ]
  }, 
];

@NgModule({
  imports: [RouterModule.forChild(PagesRoutes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {}