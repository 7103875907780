import { Component, OnInit, ElementRef } from '@angular/core';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';

import { NavItem, NavItemType } from '../shared/md/md.module';

declare var $: any;

@Component({
  selector: 'app-pages',
  template: `
  <div class="wrapper">
      <div class="sidebar" data-active-color="white" data-background-color="red" data-image="../../assets/img/sidebar-1.jpg">
          <!-- <div class="sidebar" data-color="red" data-image=""> -->
          <sidebar-cmp></sidebar-cmp>
          <div class="sidebar-background" style="background-image: url(assets/img/sidebar-1.jpg)"></div>
      </div>
      <div class="main-panel">
          <navbar-cmp></navbar-cmp>
          <router-outlet></router-outlet>
      </div>
  </div>
  `
})
export class PagesComponent implements OnInit {
  public navItems: NavItem[];
  location: Location;
  constructor(private elRef: ElementRef, location: Location) {
    this.location = location;
  }
  ngOnInit() {
    let body = document.getElementsByTagName('body')[0];
    var isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      body.classList.add("perfect-scrollbar-on");
    } else {
      body.classList.add("perfect-scrollbar-off");
    }
    $.material.init();
    var isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      var $main_panel = $('.main-panel');
      $main_panel.perfectScrollbar();
    }
  }
}
